import React, { useEffect } from "react";
import ConfigEnviroment from "./config/ConfigEnviroment";
import AuthDataService from "./common/services/auth.service";
import { ObtenerPermisosUser } from "./common/components/ObtenerPermisosUser";
import { Container } from "react-bootstrap";
import ConfigurationViewer from "./components/ConfigurationViewer";

export default function Root(props) {
  useEffect(() => {
    if (props.name === "@froneus/evi-configuration") {
      AuthDataService.validate().catch((e) => {
        if (e.response.status === 401) {
          window.location.href = ConfigEnviroment.getLoginUrl();
        }
      });
    }
    ObtenerPermisosUser();
  }, [props]);

  return (
    <Container>
      <ConfigurationViewer />;
    </Container>
  );
}
