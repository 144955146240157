import { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import { viewTypes } from "../common/types/views.type";
import { ShowView } from "../common/components/ShowView";
import { InitialView } from "./InitialView";
import bot from "../icons/bot.json";
import user from "../icons/user.json";
import usergroup from "../icons/usersgroup.json";
import client from "../icons/client.json";
import chart from "../icons/chart.json";
import call from "../icons/call.json";
import campgroup from "../icons/group1.json";
import ws from "../icons/service.json";
import ast from "../icons/net.json";
import locution from "../icons/locution.json";
import entity from "../icons/entities.json";
import intention from "../icons/intentions.json";
import kpis from "../icons/kpis.json";
import kpisgpruop from "../icons/group2.json";
import form from "../icons/form.json";

export default function ConfigurationViewer() {
  useEffect(() => {
    armarArray();
  }, []);

  const {
    vnConfiguracion: viewName,
    vfAbmClientes,
    vfAbmUsuarios,
    vfAbmAnalytics,
    vfAbmGruposUsuarios,
    vfAbmProveedores,
    vfAbmAsterisks,
    vfAbmCategorias,
    vfAbmGrupos,
    vfAbmLocuciones,
    vfAbmEntidades,
    vfAbmIntenciones,
    vfAbmBodyWs,
    vfAbmKpis,
    vfAbmGruposKpis,
    vfABmFromulariosAuditorias,
  } = viewTypes;

  const [opciones, setOpciones] = useState([]);

  const armarArray = () => {
    const op = [];

    ShowView(vfAbmUsuarios, viewName) &&
      op.push({
        category: "site",
        label: "Usuarios",
        icon: user,
        view: "Usuarios",
        description:
          "Gestionar la configuración de usuarios de la plataforma. Datos a modificar: nombre, estado, email, contraseña, padre, cliente, grupo, vendor y asterisk. Modificación de permisos. Visualización de hijos.",
      });

    ShowView(vfAbmClientes, viewName) &&
      op.push({
        category: "site",
        label: "Clientes",
        icon: client,
        view: "Clientes",
        description:
          "Gestionar la configuración de clientes. Datos a modificar: nombre, rubro, tiempo de actualización de notificaciones, creditos, key reporte, reintentos, tipo de validador, idioma de traduccion, key TTS y voz deTTS. Activar: estado, configuracion AMD, grabar llamados, grabar tranferencia, notificaciones y sincronizar TTS.",
      });

    ShowView(vfAbmGruposUsuarios, viewName) &&
      op.push({
        category: "site",
        label: "Grupos usuarios",
        icon: usergroup,
        view: "Grupos de usuarios",
        description: "Gestionar la configuración de grupos de usuarios.",
      });

    ShowView(vfAbmAnalytics, viewName) &&
      op.push({
        category: "site",
        label: "Analytics",
        icon: chart,
        view: "Analytics",
        description: "Gestionar la configuración de los reporte PBI.",
      });

    ShowView(vfAbmProveedores, viewName) &&
      op.push({
        category: "calls",
        label: "Proveedores",
        icon: call,
        view: "Proveedores",
        description:
          "Gestionar de la configuración de las troncales de los proveedores y sus asterisk. Definir canales, cps y contextos.",
      });

    ShowView(vfAbmAsterisks, viewName) &&
      op.push({
        category: "calls",
        label: "Asterisks",
        icon: ast,
        view: "Asterisks",
        description:
          "Gestionar la configuración de asterisk. Definir usuario, puerto, password y contexto para las llamadas.",
      });

    ShowView(vfAbmCategorias, viewName) &&
      op.push({
        category: "calls",
        label: "Categorías",
        icon: bot,
        view: "category",
        description:
          "Gestionar la configuración de categorías para la agrupación de voicebots.",
      });

    ShowView(vfAbmGrupos, viewName) &&
      op.push({
        category: "calls",
        label: "Grupos campañas",
        icon: campgroup,
        view: "groups",
        description: "Gestionar la configuración de agrupador de campañas.",
      });

    ShowView(vfAbmIntenciones, viewName) &&
      op.push({
        category: "bot",
        label: "Intenciones",
        icon: intention,
        view: "Intenciones",
        description:
          "Gestionar intenciones y entidades para usar en los nodos que consuman modelos de RASA en los voicebots.",
      });

    ShowView(vfAbmEntidades, viewName) &&
      op.push({
        category: "bot",
        label: "Entidades",
        icon: entity,
        view: "Entidades",
        description:
          "Gestionar la configuración de las entidades del modelo de opciones. Definir contexto y opciones para el modelo de opciones.",
      });

    ShowView(vfAbmLocuciones, viewName) &&
      op.push({
        category: "bot",
        label: "Locuciones",
        icon: locution,
        view: "Locuciones",
        description: "Importar locuciones para usar en los voicebots.",
      });

    ShowView(vfAbmBodyWs, viewName) &&
      op.push({
        category: "bot",
        label: "BodyWS",
        icon: ws,
        view: "BodyWS",
        description:
          "Gestionar la configuración de los datos a utilizar en el nodo de tipo web service. Definir atributos.",
      });

    ShowView(vfAbmKpis, viewName) &&
      op.push({
        category: "audit",
        label: "KPIs",
        icon: kpis,
        view: "KPIs",
        description:
          "Gestiona los detalles específicos de cada indicador y sus definiciones para facilitar la gestión del modelo de auditorias.",
      });

    ShowView(vfAbmGruposKpis, viewName) &&
      op.push({
        category: "audit",
        label: "Grupos KPIs",
        icon: kpisgpruop,
        view: "Grupos KPIs",
        description:
          "Gestiona la agrupación de indicadores relacionados para ofrecer una visión estructurada de diferentes aspectos del análisis.",
      });

    ShowView(vfABmFromulariosAuditorias, viewName) &&
      op.push({
        category: "audit",
        label: "Speech Analytics",
        icon: form,
        view: "Formularios",
        description:
          "Gestiona los grupos de indicadores y sus correspondientes indicadores que consume el modelo de auditorias.",
      });

    setOpciones(op);
  };

  return (
    <Container className="evi-configuration">
      <InitialView opciones={opciones} />
    </Container>
  );
}
